import React, { useState, useEffect, useContext } from 'react'
import ModalReservation from '../ModalReservation/modalReservation'
import LoadingContext from '../utils/loading.context'
import { Image } from 'primereact/image'
import { useMediaQuery } from '@mui/material'
import './reservations.css'
import './data.css'
import './courts.css'
import axios from "axios"
import M_Select from '../utils/M_Select/M_Select'
import { defaultURl } from '../utils/url.service'
import M_Button from 'src/components/utils/M_Button/M_Button'
import { useNavigate } from 'react-router-dom'

export default function Reservations() {
    const navigate = useNavigate()
    const media575 = useMediaQuery('(max-width: 575px)')
    const ctx = useContext(LoadingContext)
    const [openModal, setOpenModal] = useState(false)

    //principal user dictionary
    const [userSelectDic, setUserSelectDic] = useState(new Map())

    //backend dataSets
    const [currentWeek, setCurrentWeek] = useState([])
    const [CourtsHoursFields, setCourtsHoursFields] = useState([])
    const [curtMins, setCurtMins] = useState([])

    var dayDate = new Date()
    var year = dayDate.getFullYear()
    var month = String(dayDate.getMonth() + 1).padStart(2, '0')
    var day = String(dayDate.getDate()).padStart(2, '0')
    var formatedDate = `${year}-${month}-${day}T00:00:00`
    const [weekDate, setWeekDate] = useState(formatedDate)
    const [selectedDate, setSelectedDate] = useState(null)
    const selectHour = ['7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00']
    const [selectedHour, setSelectedHour] = useState('7:00')

    const backendCall = () => {
        ctx.setIsLoad(true)
        axios.get(`${defaultURl}availability/${weekDate}?hourFilter=${selectedHour}`)
            .then(function (response) {
                // console.log("res", response.data.Reservation)
                if (response.name === "AxiosError") {
                    setCurrentWeek([])
                    return
                }
                setCurrentWeek(response.data.Reservation)

                const matchingDates = response.data.Reservation.filter(date => date.Datetime === weekDate)
                const date = matchingDates[0]
                const updates = new Map([
                    ["Datetime", date.Datetime],
                    ["Day", date.Day],
                    ["DayWeek", date.DayWeek],
                    ["Month", date.Month],
                    ["Year", date.Year],
                    ["CourtName", ''],
                    ["AircourtsId", ''],
                    ["ReservationTime", ''],
                    ["Duration", ''],
                    ["LadderTournament", ""],
                    ["Name", ''],
                    ["Surname", ''],
                    ["Email", ''],
                    ["Phone", ''],
                    ["Partner", ''],
                    ["Player2", ''],
                    ["Player3", ''],
                    ["Player4", ''],
                    ["TypeGame", 'Single'],
                    ["CourtPrice", '']
                ])
                setUserSelectDic(updates)
                setCourtsHoursFields(date.Courts)
                setCurtMins([])
            })
            .catch(function (error) {
                return error
            })
            .finally(() => {
                ctx.setIsLoad(false)
            })
    }

    useEffect(() => {
        backendCall()
        // eslint-disable-next-line
    }, [weekDate, selectedHour])

    // useEffect(() => {
    //     console.log("userSelectDic", userSelectDic)
    //     // eslint-disable-next-line
    // }, [userSelectDic])

    useEffect(() => {
        if (selectedDate === null && currentWeek.length > 0) {
            setSelectedDate(currentWeek[0].Datetime)
            setWeekDate(currentWeek[0].Datetime)
        }
    }, [selectedDate, currentWeek])

    const redirectToExternalPage = () => {
        window.open('https://www.livroreclamacoes.pt/Inicio/', '_blank')
    }

    return (
        <div className='reservationsContainer'>
            <div className='contentorData'>
                <div className='secoundDivReservationsData'>
                    <div className='dataCurrentWeekContainer'>
                        <div className='divCalendDataPage'>
                            <div className='homePageIcon'>
                                <img src="/icons/calend.png" alt="Horário" />
                            </div>
                        </div>
                    </div>
                    <div className='dataCurrentWeek'>
                        {currentWeek.map((el, elIndex) => {
                            const isSelected = selectedDate === el.Datetime
                            return (
                                <div className={`datesMainDiv ${!el.Open ? 'disabled' : ''}`}
                                    key={elIndex}
                                    style={isSelected && el.Open ? { backgroundColor: 'var(--color-black-light)', color: 'var(--color-white)', borderRadius: '5px', fontWeight: 'bold' } : {}}
                                    onClick={() => {
                                        if (!isSelected && el.Open) {
                                            setWeekDate(el.Datetime)
                                            setSelectedDate(el.Datetime)
                                        }
                                    }}
                                    disabled={!el.Open}
                                >
                                    <div style={{ marginTop: '5px', fontSize: '13px' }}>{el.Month ? el.Month.substring(0, 3) : ''}</div>
                                    <div>{el.DayWeek ? el.DayWeek.substring(0, 3) : ''}  {el.Day ? el.Day : ''}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='divClockDataPage'>
                        <div className='homePageIcon clockMarginleft'>
                            <img src="/icons/clock2.png" alt="Horário" />
                        </div>
                        <div className='divSelectDataPage'>
                            <M_Select
                                color={"#262626"}
                                letterColor={"var(--color-black)"}
                                label={"Hora"}
                                onChange={(e) => setSelectedHour(e.target.value)}
                                options={selectHour}
                                value={selectedHour}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='contentorReservationsCourt'>
                <div className='courtsMainDivInfo'>
                    <div className='courtsMainDivInfoLeft'> </div>
                    <div className='courtsMainDivInfoRight'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '15px', height: '9px', border: '1px solid var(--color-black-light)', marginRight: '10px', marginLeft: '15px', borderRadius: '2px' }}></div>
                            <div style={{ fontSize: '11px' }}>Disponível</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '15px', height: '9px', backgroundColor: 'var(--color-grey)', marginRight: '10px', marginLeft: '10px', borderRadius: '2px' }}></div>
                            <div style={{ fontSize: '11px' }}>Não disponível</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '15px', height: '9px', backgroundColor: 'var(--color-black-light)', marginRight: '10px', marginLeft: '10px', borderRadius: '2px' }}></div>
                            <div style={{ fontSize: '11px' }}>Sua reserva</div>
                        </div>
                    </div>
                </div>
                <div className='courtPrincipalDiv'>
                    {CourtsHoursFields === null ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>Não é possivel efeturar qualquer marcação para este dia.</div>
                        :
                        CourtsHoursFields.map((court, courtIndex) => (
                            <div key={courtIndex} className='MainCourts'>
                                {!media575 ? <div className='courtImgDiv'>
                                    <Image src={court.CourtImg === null ? '/Images/defaultImg.jpg' : `/Images/courts/${court.CourtName}.jpg`} alt={court.CourtName} width={"135px"} height={"113px"} preview />
                                </div> : <></>}
                                <div className='nameHoursContainer'>
                                    <div style={{ display: 'flex', marginLeft: '10px', alignItems: 'center' }}>
                                        {media575 ? <div className='courtImgDiv'>
                                            <Image src={court.CourtImg === null ? '/Images/defaultImg.jpg' : `/Images/courts/${court.CourtName}.jpg`} alt={court.CourtName} width={"50px"} height={'30px'} preview />
                                        </div> : <></>}
                                        <div><img src="/icons/ball.png" alt="Horário" /></div>
                                        <div className='courtLabel'>{court.CourtName}</div>
                                    </div>
                                    <div className='courtHoursDiv' >
                                        {court.Hours.map((hour, hourIndex) => {
                                            const isSelected = userSelectDic.get("CourtName") === court.CourtName && userSelectDic.get("ReservationTime") === hour.Hour
                                            return (
                                                <div className={`courtHours ${!hour.Free ? 'disabled' : ''}`} // Check if the time is available
                                                    key={hourIndex}
                                                    style={isSelected ? { backgroundColor: 'var(--color-black-light)', color: 'var(--color-white)', fontWeight: 'bold' } : {}}
                                                    onClick={() => {
                                                        if (hour.Free) {
                                                            const updates = new Map([
                                                                ["Datetime", userSelectDic.get("Datetime") || ""],
                                                                ["Day", userSelectDic.get("Day")],
                                                                ["DayWeek", userSelectDic.get("DayWeek")],
                                                                ["Month", userSelectDic.get("Month")],
                                                                ["Year", userSelectDic.get("Year")],
                                                                ["CourtName", court.CourtName],
                                                                ["AircourtsId", court.AircourtsId],
                                                                ["ReservationTime", hour.Time],
                                                                ["Duration", ''],
                                                                ["LadderTournament", userSelectDic.get("LadderTournament") || ""],
                                                                ["Name", userSelectDic.get("Name") || ""],
                                                                ["Surname", userSelectDic.get("Surname") || ""],
                                                                ["Email", userSelectDic.get("Email") || ""],
                                                                ["Phone", userSelectDic.get("Phone") || ""],
                                                                ["Partner", userSelectDic.get("Partner") || ""],
                                                                ["Player2", userSelectDic.get("Player2") || ""],
                                                                ["Player3", userSelectDic.get("Player3") || ""],
                                                                ["Player4", userSelectDic.get("Player4") || ""],
                                                                ["TypeGame", userSelectDic.get("TypeGame") || ""],
                                                                ["CourtPrice", court.CourtName.includes("Court Ténis") ? "20" :
                                                                    court.CourtName.includes("Court Coberto") ? "22" :
                                                                        court.CourtName.includes("Court Rapido") ? "18" :
                                                                            ""
                                                                ]
                                                            ])
                                                            setUserSelectDic(updates)
                                                            if (hour.AvailableMins) {
                                                                const minsWithLabel = hour.AvailableMins.map(min => `${min} mins`)
                                                                setCurtMins(minsWithLabel)
                                                            }
                                                            setOpenModal(true)
                                                        }
                                                    }}
                                                    disabled={!hour.Free}
                                                >
                                                    {hour.Time}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            {openModal ? <ModalReservation
                openModal={openModal}
                setOpenModal={setOpenModal}
                userSelectDic={userSelectDic}
                setUserSelectDic={setUserSelectDic}
                curtMins={curtMins}
                setCurtMins={setCurtMins}
                setCourtsHoursFields={setCourtsHoursFields}
                currentWeek={currentWeek}
                setCurrentWeek={setCurrentWeek}
            /> : <></>
            }
            <div style={{ position: 'absolute', bottom: '0', width: '100%', marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <M_Button
                        onClick={() => redirectToExternalPage()}
                        bold
                        backgroundColor={'var(--color-grey-light)'}
                        label={"Livro de reclamações"}
                    />
                </div>
                <div>|</div>
                <div style={{ marginRight: '16px', display: 'flex', justifyContent: 'flex-end' }}>
                    <M_Button
                        onClick={() => navigate('termsAndConditions')}
                        bold
                        backgroundColor={'var(--color-grey-light)'}
                        label={"Termos e condições"}
                    />
                </div>
            </div>
        </div>
    )
}